import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

const routes: Routes = [
  { path: "", pathMatch: "full", redirectTo: "" },
  {
    path: "patient",
    loadChildren: () =>
      import("./patient/patient.module").then((m) => m.PatientModule),
  },
  {
    path: "allergy",
    loadChildren: () =>
      import("./allergy/allergy.module").then((m) => m.AllergyModule),
  },

];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule {

}
