<main>
  <!-- <h1>
    Hospital App
  </h1> -->
  <nav>
    <!-- <a routerLink="/">Back</a> -->
    <a routerLink="/patient">Jane</a>
    <a routerLink="/allergy">Tam</a>
  </nav>


</main>
<router-outlet></router-outlet>